import React, { useMemo } from 'react';
import scrapedData from '../../assets/scraped.json';
import { NavStateElement } from '../../core/utils/useNavState';
import { PositionsWrapper } from './wrappers';
import { ScrapedDescription } from '../../core/ScrapedBullets/ScrapedDescription';

interface JobsPageProps {}

export const JobsPage: React.FC<JobsPageProps> = ({}) => {
  const { jobs } = scrapedData;
  const dividerStyles = useMemo(() => new Array(jobs.length - 1).fill(0).map(() => Math.random()), []);
  return (
    <>
      <NavStateElement numSections={jobs.length * 2} pageTitle="Starfleet Records" isCenterLayout={false} />
      {jobs.map((job, i) => (
        <div key={job.companyName + i} style={{ marginBottom: '2em' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '1.5em' }}>
            <div style={{ width: '5em' }}>
              <img
                src={job.profilePicture}
                style={{
                  objectFit: 'cover',
                  borderRadius: '1em',
                }}
              />
            </div>
            <h2 className="go-gold">{job.companyName}</h2>
          </div>
          <PositionsWrapper $hasMany={job.positions.length > 1}>
            {job.positions.map((position) => {
              const descriptionFirstBullet = position.description.findIndex((section) => section.startsWith('-'));
              const descriptionParagraphs = position.description.slice(0, descriptionFirstBullet >= 0 ? descriptionFirstBullet : undefined);
              const descriptionBullets = descriptionFirstBullet >= 0 ? position.description.slice(descriptionFirstBullet) : [];
              return (
                <div key={position.title}>
                  <h3>{position.title}</h3>
                  <i style={{ display: 'inline-flex', gap: '.5em' }} className="go-space-white">
                    <span>{position.dates[0]}</span>
                    <span>-</span>
                    <span>{position.isCurrent ? 'Present' : position.dates[1]}</span>
                  </i>
                  <div className="go-almond-creme">
                    <ScrapedDescription description={position.description} />
                  </div>
                </div>
              );
            })}
          </PositionsWrapper>
          {i < jobs.length - 1 &&
            (dividerStyles[i] > 0.6 ? (
              <div className="lcars-bar">
                <div className="lcars-bar-inner" />
              </div>
            ) : (
              <>
                <div className="lcars-bar-slice-top" />
                <div className="lcars-bar">
                  <div className="lcars-bar-inner">
                    <div className="lcars-bar-cutout" />
                  </div>
                </div>
                <div className="lcars-bar-slice-bottom" />
              </>
            ))}
        </div>
      ))}
    </>
  );
};
