import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CascadePanel } from './CascadePanel';
import { useNavState } from '../utils/useNavState';
import { ThemeMainVerticalPanel } from './ThemeMainVerticalPanel';
import { StyledNavButtonContainer } from './wrappers';

interface NavbarPanelProps {
  navbarItems: { text: string; href: string }[];
}

const navbarClasses = ['bluey', 'orange', 'almond-creme', 'red'];

export const NavbarPanel: React.FC<NavbarPanelProps> = ({ navbarItems }) => {
  const { pathname } = useLocation();
  const [navState] = useNavState();
  return (
    <>
      <ThemeMainVerticalPanel
        isLeft={false}
        numVerticalPanels={2}
        showBottomHorizPanel={true}
        showAttributionInFirstContent={true}
        style={{
          flexGrow: 0,
        }}
      >
        <div className="banner">
          <span>{navState?.pageTitle}</span>
          <span>
            <ul style={{ margin: '0', display: 'inline-block' }}>
              <li style={{ marginLeft: '.5em', marginRight: '-.3em' }} />
            </ul>
          </span>
          Online
        </div>
        <div className="data-cascade-button-group">
          <CascadePanel />
          <StyledNavButtonContainer>
            {navbarItems.map((item, i) => (
              <div className={['button', ...(pathname === item.href ? ['blink-slower'] : [])].join(' ')} key={item.text}>
                <Link to={item.href} className={navbarClasses[i]}>
                  {item.text}
                </Link>
              </div>
            ))}
          </StyledNavButtonContainer>
        </div>
      </ThemeMainVerticalPanel>
    </>
  );
};
