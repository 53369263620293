import React from 'react';
import { NavStateElement } from '../../core/utils/useNavState';
import scrapedData from '../../assets/scraped.json';
import skills from '../../assets/skills.json';
import { ScrapedDescription } from '../../core/ScrapedBullets/ScrapedDescription';
import { SkillContainerWrapper, SkillWrapper } from './wrappers';

interface ProjectsPageProps {}

export const ProjectsPage: React.FC<ProjectsPageProps> = () => {
  return (
    <>
      <NavStateElement
        isCenterLayout={true}
        pageTitle="Projects"
        numSections={6}
        centerLayoutRightContent={
          <>
            <h2>Personal Projects</h2>
            {scrapedData.projects.map((project) => (
              <div key={project.title}>
                <h3>{project.title}</h3>
                <div className="go-almond-creme">
                  <ScrapedDescription description={project.description} />
                </div>
                <div className="buttons">
                  <a href={(project.link && new URL(project.link ?? '').searchParams.get('url')) || undefined}>View Project</a>
                </div>
              </div>
            ))}
          </>
        }
      />
      <>
        <h2>Frameworks</h2>
        <SkillContainerWrapper>
          {skills.frameworks.map(({ name, icon, pack }) => (
            <SkillWrapper className="gray go-space-white" key={name}>
              {icon && <i className={(pack ?? 'fa-brands') + ' fa-' + icon} />}
              {name}
            </SkillWrapper>
          ))}
        </SkillContainerWrapper>
        <h2>Languages</h2>
        <SkillContainerWrapper>
          {skills.languages.map(({ name, icon, pack }) => (
            <SkillWrapper className="gray go-space-white" key={name}>
              {icon && <i className={(pack ?? 'fa-brands') + ' fa-' + icon} />}
              {name}
            </SkillWrapper>
          ))}
        </SkillContainerWrapper>
      </>
    </>
  );
};
