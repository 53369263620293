import React from 'react';

interface ScrapedDescriptionProps {
  description: string[];
}

export const ScrapedDescription: React.FC<ScrapedDescriptionProps> = ({ description }) => {
  const descriptionFirstBullet = description.findIndex((section) => section.startsWith('-'));
  const descriptionParagraphs = description.slice(0, descriptionFirstBullet >= 0 ? descriptionFirstBullet : undefined);
  const descriptionBullets = descriptionFirstBullet >= 0 ? description.slice(descriptionFirstBullet) : [];
  return (
    <>
      {descriptionParagraphs.map((section) => (
        <p key={section}>{section}</p>
      ))}
      {descriptionBullets.length > 0 && (
        <ul>
          {descriptionBullets.map((bullet) => (
            <li key={bullet}>{bullet.replace(/^-/, '').trim()}</li>
          ))}
        </ul>
      )}
    </>
  );
};
