import React, { useMemo } from 'react';
import './cascadePanel.css';
import { useRandomValues } from '../utils/useRandomValues';

interface CascadePanelProps {
  rows?: number;
  cols?: number;
}

export const CascadePanel: React.FC<CascadePanelProps> = ({ rows = 6, cols = Math.floor(window.innerWidth / 100) }) => {
  const cascadeData = useRandomValues({ rows: rows, cols: cols });
  return (
    <div className="cascade-wrapper">
      <div className="data-cascade">
        {cascadeData.map((ign, i) => (
          <div className={'row-' + (i + 1)} key={'row-' + i} style={{ display: 'grid', gridTemplateColumns: `repeat(${cols}, minmax(0, 1fr))` }}>
            {cascadeData[i].map((val, j) => (
              <div className={'cascade-point dc' + (j + 1)} key={'row-' + i + '-dc' + j}>
                {val}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
