import React, { CSSProperties, useMemo } from 'react';

interface ThemeMainHorizontalSectionProps {
  isTop: boolean;
  isLeft: boolean;
  horizColors: string[];
  horizSizing: number[];
}

export const ThemeMainHorizontalSection: React.FC<ThemeMainHorizontalSectionProps> = ({ isTop, isLeft, horizColors, horizSizing }) => {
  const horizHalfSize = useMemo(() => horizSizing.map((val, i) => i !== horizSizing.length - 1 && Math.random() > 0.7), [horizSizing]);

  const commonPositioning: CSSProperties = {
    height: 40,
    width: 40,
    position: 'absolute',
    ...(isTop
      ? {
          top: '100%',
        }
      : {
          bottom: '100%',
        }),
    backgroundColor: 'black',
    zIndex: -1,
    ...(isLeft
      ? {
          right: 0,
        }
      : {
          left: 0,
        }),
  };
  return (
    <div style={{ display: 'flex', gap: '.2em', flexDirection: isLeft ? 'row' : 'row-reverse', position: 'relative' }}>
      {horizSizing.map((val, i) => (
        <div
          className={horizColors[i]}
          style={{
            height: '22px',
            maxHeight: horizHalfSize[i] ? '11px' : undefined,
            flexGrow: val,
            minWidth: i === horizSizing.length - 1 ? '45px' : undefined,
          }}
          key={i}
        />
      ))}
      <div style={commonPositioning} className={horizColors[horizColors.length - 1]} />
      <div
        style={{
          ...commonPositioning,
          [`border${isTop ? 'Top' : 'Bottom'}${isLeft ? 'Right' : 'Left'}Radius`]: '100px',
        }}
      />
    </div>
  );
};
