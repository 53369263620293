import React from 'react';
import { pic2 } from '../../assets';
import { NavStateElement } from '../../core/utils/useNavState';
import './home.css';

export const HomePage: React.FC = () => {
  return (
    <>
      <NavStateElement numSections={2} pageTitle="Main Engineering" isCenterLayout={false} />
      <div className="home-grid">
        <div>
          <img
            src={pic2}
            style={{
              filter: 'sepia(.2) contrast(1.3) saturate(1.1)',
              objectFit: 'cover',
              borderRadius: '2em',
              maxWidth: '100%',
            }}
          />
        </div>
        <div>
          <h1 style={{ textAlign: 'left' }}>Blake Stacks</h1>
          <h2 style={{ fontSize: '32px' }}>Full Stack Software Engineer</h2>
        </div>
      </div>
    </>
  );
};
