import React, { useMemo } from 'react';
import { useRandomValues } from '../utils/useRandomValues';
import { useNavState } from '../utils/useNavState';
import { StyledVerticalPanelContainer, StyledVerticalPanelSegment } from './wrappers';
import { ThemeMainVerticalPanelProps } from './types';
import { ThemeMainHorizontalSection } from './ThemeMainHorizontalSection';

const colorRotationHoriz = ['gold', 'lilac', 'bluey', 'red', 'almond-creme'];
const colorRotationVert = ['gold', 'lilac', 'bluey', 'red', 'almond-creme'];

export const ThemeMainVerticalPanel: React.FC<ThemeMainVerticalPanelProps> = ({
  isLeft,
  thinVertical,
  children,
  numVerticalPanels,
  style,
  showTopHorizPanel,
  showBottomHorizPanel,
  showAttributionInFirstContent,
  noPadding,
}) => {
  const horizSizing = useMemo(() => [1, 2, 3, 4, 5].sort(() => Math.random() * 2 - 1).map((val) => Math.pow(2, val)), []);
  const horizColors = useMemo(() => horizSizing.map((val, i) => colorRotationHoriz[(horizSizing[0] + i) % colorRotationHoriz.length]), [horizSizing]);
  const [navState] = useNavState();

  const numSections = numVerticalPanels ?? navState?.numSections ?? 4;
  const vertContent = useRandomValues({ rows: numSections, cols: 1, maxLength: thinVertical || window.innerWidth < 700 ? 5 : 7 });
  const vertSizing = useMemo(
    () => new Array(vertContent.length).fill(0).map(() => Math.pow(2, Math.round(Math.random() * 4) + 1)),
    [vertContent, navState?.numSections],
  );
  const vertColors = useMemo(
    () => vertSizing.map((val, i) => colorRotationVert[(vertSizing[0] + i) % colorRotationVert.length]),
    [vertSizing, navState?.numSections],
  );

  return (
    <div
      style={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: isLeft ? 'row' : 'row-reverse',
        paddingLeft: isLeft || noPadding ? undefined : '.5em',
        paddingRight: !isLeft || noPadding ? undefined : '.5em',
        ...style,
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, minWidth: 0 }}>
        {showTopHorizPanel && <ThemeMainHorizontalSection isLeft={isLeft} isTop={true} horizSizing={horizSizing} horizColors={horizColors} />}
        <div style={{ flexGrow: 1, padding: '1em', display: 'flex', flexDirection: 'column' }}>{children}</div>
        {showBottomHorizPanel && <ThemeMainHorizontalSection isLeft={isLeft} isTop={false} horizSizing={horizSizing} horizColors={horizColors} />}
      </div>
      <StyledVerticalPanelContainer thinVertical={thinVertical}>
        {vertContent.map((val, i) => (
          <StyledVerticalPanelSegment
            key={i}
            className={
              showAttributionInFirstContent && i === 0
                ? 'almond-creme'
                : (showTopHorizPanel && i === 0) || (i === numSections - 1 && showBottomHorizPanel)
                ? horizColors[horizColors.length - 1]
                : vertColors[i]
            }
            style={{
              flexGrow: vertSizing[i],
              paddingTop: i === 0 ? '3em' : '1.5em',
              paddingLeft: isLeft ? '.75em' : undefined,
              paddingRight: !isLeft ? '.75em' : undefined,
              alignItems: showBottomHorizPanel && i === numSections - 1 ? 'flex-start' : 'flex-end',
              justifyContent: isLeft ? 'flex-start' : 'flex-end',
              ...(i === 0 && showTopHorizPanel ? (isLeft ? { borderTopRightRadius: '100px' } : { borderTopLeftRadius: '100px' }) : {}),
              ...(i === numSections - 1 && showBottomHorizPanel
                ? isLeft
                  ? { borderBottomRightRadius: '100px' }
                  : { borderBottomLeftRadius: '100px' }
                : {}),
            }}
          >
            {showAttributionInFirstContent && i === 0 ? (
              <a href={'https://www.thelcars.com'} style={{ fontSize: '.6em', textAlign: 'right' }}>
                <div className="go-gray">LCARS Template</div>
                <span className="go-gray">Jim Robertus. ©2022</span>
              </a>
            ) : (
              <>
                {(i + 3).toString().padStart(2, '0')}-{val.toString().padStart(4, '0')}
              </>
            )}
          </StyledVerticalPanelSegment>
        ))}
      </StyledVerticalPanelContainer>
    </div>
  );
};
