import { useMemo } from 'react';

interface UseRandomValuesParams {
  rows: number;
  cols: number;
  maxLength?: number;
}

export const useRandomValues = ({ rows, cols, maxLength = 5 }: UseRandomValuesParams): string[][] => {
  return useMemo(
    () =>
      new Array(rows).fill(0).map(() => {
        return new Array(cols).fill(0).map(() => {
          const len = Math.floor(Math.random() * (maxLength - 1) + 1);
          const letterLen = Math.floor(Math.max(Math.random() - 0.5, 0));
          return (
            Math.floor(Math.random() * Math.pow(36, letterLen + 1))
              .toString(36)
              .toUpperCase() +
            Math.floor(Math.random() * Math.pow(10, len - letterLen))
              .toString()
              .padStart(len - letterLen, '0')
          );
        });
      }),
    [rows, cols, maxLength],
  );
};
