import styled from 'styled-components';

export const PositionsWrapper = styled.div<{ $hasMany: boolean }>`
  display: grid;
  gap: 2em;
  grid-template-columns: repeat(${({ $hasMany }) => ($hasMany ? 2 : 1)}, minmax(0, 1fr));

  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`;
