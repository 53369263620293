import styled from 'styled-components';

export const SkillWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6em;
  border-radius: 10em;
  padding: 0.75em 1.5em;
  font-size: 18px;
`;

export const SkillContainerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
`;
