import React from 'react';
import { useNavState } from '../utils/useNavState';
import { ThemeMainVerticalPanel } from './ThemeMainVerticalPanel';
import { CenterLayoutWrapper } from './wrappers';

interface ContentPanelProps {
  numSections?: number;
}

export const ContentPanel: React.FC<ContentPanelProps> = ({ children, numSections = 6 }) => {
  const [navState] = useNavState();
  return (
    <>
      {!navState?.isCenterLayout ? (
        <ThemeMainVerticalPanel isLeft={false} style={{ marginTop: '1em' }} showTopHorizPanel={true}>
          {children}
        </ThemeMainVerticalPanel>
      ) : (
        <CenterLayoutWrapper>
          <ThemeMainVerticalPanel isLeft={true} thinVertical={true} showBottomHorizPanel={true} showTopHorizPanel={true}>
            {children}
          </ThemeMainVerticalPanel>
          <ThemeMainVerticalPanel isLeft={false} thinVertical={true} showBottomHorizPanel={true} showTopHorizPanel={true}>
            {navState.centerLayoutRightContent}
          </ThemeMainVerticalPanel>
        </CenterLayoutWrapper>
      )}
    </>
  );
};
