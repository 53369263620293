import React from 'react';
import './App.css';
import { HomePage } from './pages/home/HomePage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { NavbarPanel } from './core/ThemePanels/NavbarPanel';
import { ContentPanel } from './core/ThemePanels/ContentPanel';
import { NavStateProvider } from './core/utils/useNavState';
import { JobsPage } from './pages/jobs/JobsPage';
import { ProjectsPage } from './pages/projects/ProjectsPage';

function App() {
  return (
    <BrowserRouter>
      <NavStateProvider>
        <NavbarPanel
          navbarItems={[
            { text: 'Home', href: '/' },
            { text: 'Positions', href: '/starfleet' },
            { text: 'Projects', href: '/projects' },
          ]}
        />
        <ContentPanel>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/starfleet" element={<JobsPage />} />
            <Route path="/projects" element={<ProjectsPage />} />
          </Routes>
        </ContentPanel>
      </NavStateProvider>
    </BrowserRouter>
  );
}

export default App;
