import styled from 'styled-components';
import { ThemeMainVerticalPanelProps } from './types';

export const CenterLayoutWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
  flex-grow: 1;

  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledVerticalPanelSegment = styled.div`
  display: flex;
  font-size: 19px;
  font-weight: bold;
  color: #000;
  padding-bottom: 1.5em;
`;

export const StyledVerticalPanelContainer = styled.div<Pick<ThemeMainVerticalPanelProps, 'thinVertical'>>`
  width: ${({ thinVertical }) => (thinVertical ? '100px' : '190px')};
  min-width: ${({ thinVertical }) => (thinVertical ? '100px' : '190px')};
  display: flex;
  flex-direction: column;
  gap: 0.2em;

  @media screen and (max-width: 700px) {
    width: 100px;
    min-width: 100px;
  }
`;

export const StyledNavButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(190px, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
  gap: 1em;

  .button,
  a {
    min-width: 190px;
    display: flex;
    flex-direction: column;
    margin: 0;
    width: unset;
  }

  @media screen and (max-width: 700px) {
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    flex-grow: 1;

    .button,
    a {
      min-width: 100px;
    }
  }
`;
