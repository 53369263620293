import React, { Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';
import { isEqual } from 'lodash';

export interface NavState {
  numSections: number;
  isCenterLayout: boolean;
  pageTitle: string;
  centerLayoutRightContent?: ReactNode;
}

// export const { useStateDeep: useNavState, Provider: NavStateProvider } = createUseStateDeep<NavState>();

const defaultNavState = { numSections: 6, isCenterLayout: false, pageTitle: 'Main Engineering' };
const NavStateContext = React.createContext<[NavState | undefined, Dispatch<SetStateAction<NavState>>]>([defaultNavState, () => {}]);

export const NavStateProvider: React.FC = ({ children }) => {
  const [state, setState] = useState(defaultNavState);
  return <NavStateContext.Provider value={[state, setState]}>{children}</NavStateContext.Provider>;
};

export const useNavState = () => useContext(NavStateContext);

export const NavStateElement: React.FC<NavState> = ({ children, ...params }) => {
  const [state, setState] = useNavState();

  useEffect(() => {
    if (!isEqual(state, params)) {
      setState({ ...state, ...Object.fromEntries(Object.entries(params).filter(([k, v]) => v !== undefined)) } as NavState);
    }
  }, [...Object.values(params)]);
  return <></>;
};
